<template>
  <!-- v-swipeleft="() => $router.go(-1)" -->
  <div
    id="goods"
    ref="goods"
    :style="
      goodsStatus === 3 && goods.integral > maxIntegralBank.integral
        ? 'bottom:1.02rem'
        : ''
    "
  >
    <div
      v-if="this.$route.name !== 'video'"
      class="nav_title"
      :style="topDistance > 0 ? '' : 'background:#fff'"
      :class="topDistance > 0 ? '' : 'van-hairline--bottom'"
    >
      <span>
        <van-icon name="arrow-left" @click="$router.go(-1)" v-if="back" />
        <van-icon name="wap-home-o" @click="$router.push('/')" v-else />
      </span>
      {{ topDistance > 0 ? "" : "商品详情页" }}
    </div>
    <van-loading size="30px" v-if="loading" class="loading" />
    <van-empty image="error" description="商品不存在" v-else-if="error" />
    <!-- 商品页 -->
    <div class="goods_box" v-else>
      <!-- 商品详情 -->
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        success-text="刷新成功"
      >
        <div class="goods_details">
          <van-swipe @change="onChange">
            <van-swipe-item v-if="goodsVideo">
              <video
                :src="goodsVideo"
                style="width: 100%; height: 100%"
                controls
                playsinline
                :poster="goods.filePath[0]"
                @play="bannerScroll = false"
                @pause="bannerScroll = true"
              ></video>
            </van-swipe-item>
            <van-swipe-item
              @click="$route.name === 'goods-details' ? imgPreview(index) : ''"
              v-for="(img, index) in goods.filePath"
              :key="index"
              ><img v-lazy="img" alt=""
            /></van-swipe-item>
            <template #indicator>
              <div class="custom_indicator" v-if="imgLength > 1">
                <span>{{ current + 1 }}</span
                >{{ imgLength }}
              </div>
            </template>
          </van-swipe>
          <div class="taste" ref="taste" v-if="goodsStatus === 1">
            <div class="taste_price">
              <div class="price">
                <h3><span>¥</span>{{ goods.entity.price.toFixed(2) }}</h3>
                <p>限购1份，超值限量</p>
              </div>
              <p class="old_price">
                ￥{{
                  userType === "PURCHASE"
                    ? priceDetails.tradePrice.toFixed(2)
                    : priceDetails.retailPrice.toFixed(2)
                }}
              </p>
            </div>
            <h3 class="right" @click="$router.push('/earlyAdopters')">
              新人尝鲜价<span><svg-icon icon-class="arrow_right" /></span>
            </h3>
          </div>
          <div class="seckill" ref="seckill" v-else-if="goodsStatus === 2">
            <div class="seckill_price">
              <div class="price">
                <h3>
                  <span>¥</span>{{ seckillPrice.retailSeckillPrice.toFixed(2) }}
                </h3>
                <div
                  class="progress_bar"
                  :style="{
                    '--barWidth':
                      Math.round(
                        (seckillPrice.restAccount /
                          seckillPrice.inventoryCount) *
                          100
                      ) + '%',
                  }"
                >
                  <span
                    >库存{{
                      Math.round(
                        (seckillPrice.restAccount /
                          seckillPrice.inventoryCount) *
                          100
                      )
                    }}%</span
                  >
                </div>
              </div>
              <p>
                ￥{{
                  userType === "PURCHASE"
                    ? seckillPrice.tradePrice.toFixed(2)
                    : seckillPrice.retailPrice.toFixed(2)
                }}
              </p>
            </div>
            <div class="countdown">
              <h3 @click="$router.push('/seckill')">
                限时秒杀<span><svg-icon icon-class="arrow_right" /></span>
              </h3>
              <!-- :time="
                  seckillPrice.seckillEnableState
                    ? seckillPrice.seckillEndTime - new Date()
                    : seckillPrice.seckillStartTime - new Date()
                " -->
              <p>
                {{
                  seckillPrice.seckillEnableState === 1
                    ? "距离结束"
                    : "距离开始"
                }}<van-count-down
                  :time="
                    seckillPrice.seckillEnableState === 1
                      ? seckillPrice.seckillEndTime - new Date()
                      : seckillPrice.seckillStartTime - new Date()
                  "
                  @finish="finish"
                >
                  <template #default="timeData">
                    <span class="block">{{
                      timeData.hours < 10
                        ? "0" + timeData.hours
                        : timeData.hours
                    }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{
                      timeData.minutes < 10
                        ? "0" + timeData.minutes
                        : timeData.minutes
                    }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{
                      timeData.seconds < 10
                        ? "0" + timeData.seconds
                        : timeData.seconds
                    }}</span>
                  </template>
                </van-count-down>
              </p>
            </div>
          </div>
          <div
            class="details"
            ref="details"
            :style="goodsStatus ? 'padding-top:12px' : ''"
          >
            <div class="details_box">
              <div v-if="goodsStatus === 0 || goodsStatus === 4" class="price">
                <div v-if="userType === 'PURCHASE'" style="margin-bottom: 6px">
                  店长价
                  <span
                    >￥<b>{{ tradePrice }}</b
                    >/{{ goods.prickle }}</span
                  >
                  <span class="old_price" v-if="goodsStatus === 4"
                    >￥{{ priceDetails.tradePrice }}
                  </span>
                </div>
                <div>
                  会员价
                  <span
                    >￥<b>{{ retailPrice }}</b
                    >/{{ goods.prickle }}</span
                  >
                  <span class="old_price" v-if="goodsStatus === 4"
                    >￥{{ priceDetails.retailPrice }}
                  </span>
                </div>
              </div>
              <b v-else-if="goodsStatus === 3" class="integral"
                >{{ goods.integral }}积分</b
              >
              <!-- <li
                  v-if="
                    userType === 'PURCHASE' &&
                    priceDetails.conditionSetting === 2
                  "
                >
                  订单起订量：<span>{{
                    priceDetails.tradeMinimumQuantity
                  }}</span>
                </li>
                <li
                  v-if="
                    userType === 'PURCHASE' &&
                    priceDetails.conditionSetting === 1
                  "
                >
                  订单起订价格：<span
                    >{{ priceDetails.tradeStartingPrice | Price }}元</span
                  >
                </li> -->

              <div class="goods_name">
                <div class="new_icon" v-if="goods.newProducts">新品</div>
                <div class="name">
                  <h3>{{ goods.commodityName }}</h3>
                  <p>{{ goods.characteristics }}</p>
                </div>
              </div>
              <div class="hot_selling" v-if="rank">
                <div class="left">
                  <span class="icon"
                    ><svg-icon icon-class="rxbd_icon" /> 热销榜单</span
                  >
                  <b>TOP{{ rank.ranking }}</b>
                </div>
                <span
                  class="right"
                  @click="
                    $router.push(`/hotList?categoryId=${rank.categoryId}`)
                  "
                  ><svg-icon icon-class="arrow_right"
                /></span>
              </div>
              <div class="bar_code">
                <p>
                  商品条形码&nbsp; <span>{{ goods.barcode }}</span>
                </p>
                <a><svg-icon icon-class="zheng_icon" /> 认证查询</a>
              </div>
              <van-cell-group :border="false">
                <van-cell is-link @click="show = true">
                  <template #title>
                    品牌　<span>·</span>　产地　<span>·</span>　保质期限　<span>·</span>　储存方式
                  </template>
                </van-cell>
              </van-cell-group>
            </div>
            <van-cell-group
              :border="false"
              class="limit"
              v-if="goodsStatus !== 3 && (!goods.deduction || buyCount)"
            >
              <van-cell is-link>
                <template #title>
                  <!-- deduction -->
                  <span class="title">限制</span>
                  <span class="text" v-if="!goods.deduction"
                    >特定商品不可抵扣平台积分</span
                  ><span class="limit_num" v-if="buyCount"
                    >限购{{ buyCount }}件</span
                  >
                </template>
              </van-cell>
            </van-cell-group>
            <div class="dispatching">
              <ul>
                <li>
                  <span>配送方式</span>
                  <span>{{ goods.freeShipping ? "包邮" : "自提" }}</span>
                </li>
                <li>
                  <span>退货</span>
                  <span>
                    <svg-icon
                      icon-class="zc_icon"
                      style="margin-right: 4px"
                      v-if="!goods.returnSetting"
                    />
                    {{ goods.returnSetting ? "不" : "" }}支持7天无理由退货
                  </span>
                </li>
                <li>
                  <span>发货</span>
                  <span>新疆/西藏等边远地区不发货，解释权归平台</span>
                </li>
                <li>
                  <span>运费</span>
                  <span>包邮</span>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="graphic_details"
            v-html="goods.commodityContent"
            v-lazy-container="{ selector: 'img' }"
          ></div>
        </div>
      </van-pull-refresh>
      <!-- 底部导航栏 -->
      <van-goods-action class="van-hairline--top bottom_nav">
        <div
          class="integral_tips"
          v-if="goodsStatus === 3"
          v-show="goods.integral > maxIntegralBank.integral"
        >
          <div class="left">
            <p>可用积分{{ maxIntegralBank.integral }}，你的积分不足</p>
            <span><van-icon name="warning-o" />连盟会员积分不支持叠加使用</span>
          </div>
          <div class="right">
            <!-- <router-link to="/">去赚取积分法 &gt;</router-link> -->
          </div>
        </div>
        <transition name="van-fade" v-else>
          <div v-show="promoterTips">
            <div class="promoter_tips" v-if="!promoter">
              <div class="notCertifiedYet">
                <p>认证推广员，分享商品获取专属权益</p>
                <button
                  @click="
                    isLogin
                      ? (fyTipsShow = true)
                      : $router.push(
                          `/login?redirect_uri=${encodeURIComponent(
                            $route.fullPath
                          )}`
                        )
                  "
                >
                  去认证
                </button>
                <svg-icon icon-class="close" @click="promoterTips = false" />
              </div>
            </div>
            <div class="commission" v-else-if="commission">
              <p>
                分享推广预估佣金<span>{{ commission }}元</span
                ><svg-icon
                  icon-class="explain"
                  @click="$router.push('/promotion/commissionRule')"
                />
              </p>
              <button @click="saveThePoster">保存海报分享</button>
            </div>
          </div>
        </transition>

        <van-goods-action-icon
          @click="$router.replace(`/store?storeId=${goods.storeId}`)"
          v-if="$route.name === 'goods-details'"
        >
          <template #icon>
            <svg-icon icon-class="store_icon" />
          </template>
          <template #default>
            <p>店铺</p>
          </template>
        </van-goods-action-icon>
        <van-goods-action-icon
          :badge="quantity > 99 ? '99+' : quantity"
          @click="$router.push('/home/shoppingCart')"
          class="shopping_cart"
          v-if="$route.name === 'goods-details'"
        >
          <template #icon>
            <svg-icon icon-class="tabble_shopping_normal_icon" />
          </template>
          <template #default>
            <p>购物车</p>
          </template>
        </van-goods-action-icon>
        <van-button
          color="#F3AD3D"
          round
          text="加入购物车"
          @click="buyShowMethod"
          v-if="
            $route.name === 'goods-details' &&
            (goodsStatus !== 2 || seckillPrice.seckillEnableState !== 1)
          "
        />
        <!-- v-show="goods.integral >= maxIntegralBank.integral" -->
        <!-- #9d9d9f -->
        <van-button
          class="buy"
          round
          :style="{ margin: goodsStatus === 3 ? '0' : '' }"
          :color="
            goodsStatus === 3
              ? goods.integral > maxIntegralBank.integral
                ? '#c9c9c9'
                : '#F91C12'
              : '#F91C12'
          "
          :text="
            goodsStatus === 3
              ? goods.integral > maxIntegralBank.integral
                ? '积分不足'
                : '立即兑换'
              : '立即购买'
          "
          @click="buyShowMethod"
        />
      </van-goods-action>
      <!-- 新人价限购提示框 -->
      <van-dialog
        v-model="tastingTipsShow"
        confirm-button-text="去购物车"
        message="新人尝鲜价总共限购1件
        购物车已加购新人专享商品，快去买单吧~"
        :close-on-click-overlay="true"
        class="tasing_tips"
        @confirm="$router.push({ name: 'shopping-cart' })"
      >
        <template #title>
          <svg-icon icon-class="close" @click="tastingTipsShow = false" />
        </template>
      </van-dialog>
      <!-- 商品属性弹出窗 -->
      <van-action-sheet
        v-model="show"
        title="商品属性"
        class="characteristic_show"
      >
        <van-cell title="生产厂商" :value="goods.manufacturer" />
        <van-cell title="生产厂址" :value="goods.address" />
        <van-cell title="归属品牌" :value="goods.brandName" />
        <van-cell title="商品产地" :value="goods.province + goods.city" />
        <van-cell title="商品重量" :value="`${goods.weight}千克`" />
        <van-cell title="商品规格" :value="goods.specsParameter" />
        <van-cell title="储藏方法" :value="goods.conditions" />
        <van-cell title="计量单位" :value="goods.prickle" />
        <van-cell title="保质期限" :value="`${goods.expirationDate}天`" />
        <!-- <van-cell title="商品特点" :value="goods.characteristics" /> -->
      </van-action-sheet>
      <!-- 商品规格选择弹出窗 -->
      <van-action-sheet
        v-model="buyShow"
        :safe-area-inset-bottom="false"
        class="buy_show"
        @closed="closeShow"
      >
        <div class="content">
          <div class="top">
            <img :src="seleteImg ? seleteImg : goods.filePath[0]" alt="" />
            <div class="price">
              <b v-if="goodsStatus === 1">{{
                goods.entity.price.toFixed(2)
              }}</b>
              <b
                v-else-if="
                  goodsStatus === 2 && seckillPrice.seckillEnableState === 1
                "
                >{{ seckillPrice.retailSeckillPrice.toFixed(2) }}</b
              >
              <b v-else-if="goodsStatus === 3">{{ goods.integral }}积分</b>
              <b v-else-if="userType === 'PURCHASE'"
                ><span>￥</span>{{ tradePrice }}</b
              >
              <b v-else><span>￥</span>{{ retailPrice }}</b>
              <p>已选,{{ quantity2 }}{{ goods.prickle }}</p>
            </div>
            <svg-icon icon-class="close" @click="buyShow = false" />
          </div>
          <div class="Sku">
            <div class="specification">
              <div
                v-for="(list, index) in goods.storeCommodityAttributeEntities"
                :key="index"
              >
                <p>{{ list.attributeName }}</p>
                <ul>
                  <li
                    v-for="(item, idx) in list.attributeValue"
                    :key="idx"
                    :style="
                      list.index === idx ? 'background:#009A4D;color:#fff' : ''
                    "
                    @click="selectAttribute(list, idx)"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="quantity">
              <p>购买数量</p>
              <span v-if="goodsStatus === 1">限量1件</span>
              <van-stepper
                v-else-if="userType === 'PURCHASE'"
                v-model="quantity2"
                :min="
                  priceDetails.conditionSetting == 2
                    ? priceDetails.tradeMinimumQuantity
                    : 1
                "
                :max="
                  goodsStatus === 3
                    ? Math.trunc(maxIntegralBank.integral / goods.integral)
                    : buyCount
                    ? buyCount
                    : Infinity
                "
                :step="
                  priceDetails.multiple ? priceDetails.tradeMinimumQuantity : 1
                "
              />
              <van-stepper
                v-else
                v-model="quantity2"
                :min="1"
                :max="
                  goodsStatus === 3
                    ? Math.trunc(maxIntegralBank.integral / goods.integral)
                    : buyCount
                    ? buyCount
                    : Infinity
                "
              />

              <p
                class="condition"
                v-if="
                  userType === 'PURCHASE' && priceDetails.conditionSetting == 2
                "
              >
                (起订量≥{{ priceDetails.tradeMinimumQuantity
                }}{{ priceDetails.prickle
                }}{{ priceDetails.multiple ? ",整倍订货" : "" }})
              </p>
              <p
                class="condition"
                v-if="
                  userType === 'PURCHASE' && priceDetails.conditionSetting == 1
                "
              >
                (起订价≥￥{{ priceDetails.tradeStartingPrice | Price }})
              </p>
            </div>
          </div>
          <van-goods-action>
            <van-goods-action-button
              color="#F3AD3D"
              text="加入购物车"
              @click="onAddCartClicked"
              v-if="
                ($route.name === 'goods-details' || $route.name === 'video') &&
                (goodsStatus !== 2 || seckillPrice.seckillEnableState !== 1)
              " />
            <van-goods-action-button
              color="#F91C12"
              :text="goodsStatus === 3 ? '立即兑换' : '立即购买'"
              @click="onBuyClicked"
          /></van-goods-action>
        </div>
      </van-action-sheet>
      <!-- 登录提示 -->
      <van-dialog
        v-model="show2"
        :showConfirmButton="false"
        :showCancelButton="false"
      >
        <svg-icon icon-class="close" @click="show2 = false" />
        <div class="title">提示</div>
        <div class="content">
          <p>已有会员账号？请登录</p>
          <p>还没有会员账号？请注册</p>
          <div>
            <!-- <van-button
              round
              type="info"
              color="#fb5d20"
              plain
              :to="`/register?redirect_uri=${encodeURIComponent($route.fullPath)}`"
            >
              &nbsp;注册会员&nbsp;</van-button
            > -->
            <van-button
              round
              type="info"
              color="#fb5d20"
              :to="`/login?redirect_uri=${encodeURIComponent($route.fullPath)}`"
            >
              &nbsp;&emsp;注册/登录&emsp;&nbsp;</van-button
            >
          </div>
        </div>
      </van-dialog>
      <!-- 店长提示 -->
      <van-dialog
        v-model="show3"
        :showConfirmButton="false"
        :showCancelButton="false"
      >
        <svg-icon icon-class="close" @click="show3 = false" />
        <div class="title">提示</div>
        <div class="content">
          <p>店长请使用PDA终端开展店长业务。</p>
          <div>
            <van-button
              round
              type="info"
              color="#fb5d20"
              @click="show3 = false"
            >
              &nbsp;&emsp;知道了&emsp;&nbsp;</van-button
            >
          </div>
        </div>
      </van-dialog>
      <!-- 分佣提示 -->
      <van-dialog
        v-model="fyTipsShow"
        :showConfirmButton="false"
        :showCancelButton="false"
        className="fyTips"
      >
        <svg-icon icon-class="close" @click="fyTipsShow = false" />
        <div class="header">提示</div>
        <div class="content">
          是否加入正品汇分佣推广?<br />
          加入后享受专属权益哦~<br />
        </div>
        <div class="footer">
          <button class="more" @click="$router.push('promotion/instructions')">
            了解更多
          </button>
          <button
            class="authentication"
            @click="$router.push(`promotion/promotionRegistration`)"
          >
            去认证
          </button>
        </div>
      </van-dialog>
    </div>
    <functionButton
      v-if="!error"
      :homePageBtn="true"
      :shareBtn="true"
      :domNode="$refs.goods"
      :shareInfo="{
        ...goods,
        ...priceDetails,
        retailPrice,
        seckillPrice,
        goodsStatus,
      }"
    />
  </div>
</template>

<script>
import { ImagePreview, Dialog } from "vant";
import { mapState } from "vuex";
import { preferential, btoa } from "@/utils/validate";
import { WechatSharing } from "@/utils/sdk";
import sharePopup from "@/components/SharePopup/index.js";

export default {
  name: "goods-details",
  props: ["barcode", "enterpriseId"],

  computed: {
    ...mapState("user", [
      "isLogin",
      "userInfo",
      "userType",
      "quantity",
      "tzInfo",
      "maxIntegralBank",
      "promoter",
    ]),
  },
  data() {
    return {
      showComponent: false,
      current: 0, //轮播图片索引
      imgLength: 0, //轮播图片总数
      goods: "", //商品详情
      priceDetails: "",
      preferential: 0,
      retailPrice: 0, //会员价格
      tradePrice: 0, //店长价格
      isLoading: false, //下拉刷新
      show: false, //商品属性
      buyShow: false,
      quantity2: 1,
      goodsVideo: "",
      error: false,
      loading: true,
      seleteImg: "",
      goodsStatus: 0, //0无特殊,1新人尝鲜,2限时秒杀,3积分商品,4折扣价
      topDistance: 1, //滚动条距离
      seckillPrice: "", //秒杀价
      buyCount: 0, //限购
      rank: null, //排行
      tastingTipsShow: false, //新人尝鲜限购提示
      back: false, //返回键和主页键切换

      show2: false, //未登录提示
      show3: false, //登录需pda
      fyTipsShow: false,
      promoterTips: true,
      commission: null,
    };
  },
  filters: {
    characteristics(val) {
      if (val) {
        return val;
      } else {
        return "无";
      }
    },
    Price(num) {
      if (num === null) {
        return "-";
      } else if (String(Number(num)).indexOf(".")) {
        // return num;
        return num.toFixed(2);
      } else {
        return num;
      }
    },
  },
  created() {
    if (this.$route.query.promoterUuid)
      sessionStorage.setItem("promoterUuid", this.$route.query.promoterUuid);
    if (window.history.length > 2) this.back = true;
    if (this.$route.query.id) {
      // if (this.$route.query.id && !sessionStorage.getItem("userUuid")) {
      sessionStorage.setItem("userUuid", this.$route.query.id);
      // }
    } else {
      if (sessionStorage.getItem("userUuid"))
        this.$router.replace({
          // path: "/GoodsDetails",
          query: {
            barcode: this.$route.query.barcode,
            enterpriseId: this.$route.query.enterpriseId,
            commId: this.$route.query.commId,
            integralGoodsId: this.$route.query.integralGoodsId,
            id: sessionStorage.getItem("userUuid")
              ? sessionStorage.getItem("userUuid")
              : "",
            taskId: this.$route.query.taskId
              ? this.$route.query.taskId
              : undefined,
          },
        });
    }
    // if (this.$store.state.user.isLogin && !this.$store.state.user.tzInfo) {
    //   this.$store.dispatch("user/getTzInfo");
    // }
    if (this.$route.query.integralGoodsId) {
      this.getIntegralGoodsDetails();
    } else {
      this.getDetails();
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollx, true);
  },
  methods: {
    handleScrollx() {
      if (this.$refs.taste) {
        this.topDistance = this.$refs.taste.getBoundingClientRect().top - 44;
      } else if (this.$refs.seckill) {
        this.topDistance = this.$refs.seckill.getBoundingClientRect().top - 44;
      } else if (this.$refs.details) {
        this.topDistance = this.$refs.details.getBoundingClientRect().top - 44;
      }
      // console.log("滚动高度", window.pageYOffset)
    },
    //下拉刷新
    onRefresh() {
      setTimeout(() => {
        if (this.$route.query.integralGoodsId) {
          this.getIntegralGoodsDetails();
        } else {
          this.getDetails();
        }
      }, 1000);
    },
    //获取商品详情
    async getDetails() {
      this.goodsStatus = 0;
      this.buyCount = 0;
      if (this.$route.query.commId) {
        var data = await this.$API.goods.getCommodityDetails({
          object: {
            commId: this.$route.query.commId,
          },
        });
      } else {
        var data = await this.$API.goods.getCommodityDetails({
          object: {
            barcode: this.$route.query.barcode
              ? this.$route.query.barcode
              : this.barcode,
            enterpriseId: this.$route.query.enterpriseId
              ? this.$route.query.enterpriseId
              : this.enterpriseId,
          },
        });
      }
      // console.log(data.data.data);

      if (
        data.data.code === "FAILED" ||
        data.data.data.freeze ||
        data.data.data.examineState !== 1 ||
        !data.data.data.enableState
      ) {
        this.error = true;
        this.loading = false;
        return;
      }
      this.goods = data.data.data;
      // this.goods.storeCommodityAttributeEntities.push(
      //   JSON.parse(
      //     JSON.stringify(this.goods.storeCommodityAttributeEntities[0])
      //   )
      // );
      // this.goods.storeCommodityAttributeEntities[0].attributeName = "码数";

      if (this.goods.storeCommodityAttributeEntities) {
        this.goods.storeCommodityAttributeEntities.forEach((item) => {
          this.$set(item, "index", -1);
          item.attributeValue = item.attributeValue.split(",");
          item.filePath = item.filePath.split(",");
          item.id = item.id.split(",");
        });
      }

      this.imgLength = 0;
      this.goods.commodityContent = this.goods.commodityContent.replace(
        /src/g,
        "data-src"
      );

      let img = [];
      this.goods.filePath.split(",").forEach((element) => {
        this.imgLength++;
        if (element.endsWith(".mp4")) {
          this.goodsVideo = element;
        } else {
          img.push(element);
        }
      });
      this.goods.filePath = img;

      if (this.isLogin && this.goods.entity) await this.getTastingPrice(); //查看用户是否享受尝鲜价

      await this.getSeckillPrice(); //秒杀价

      await this.goodsPrice(); //商品价格

      if (this.promoter) await this.getCommodityCommission(); //预估佣金
      // this.$nextTick(function () {
      //   let video = document.querySelectorAll("video")[0];
      // console.log(video);
      // if (video) video.play();
      // });
      let shareInfo = {
        title: this.goods.commodityName,
        desc: this.goods.characteristics,
        imgUrl: this.goods.filePath[0],
        link: window.location.hash,
      };
      WechatSharing(shareInfo);
    },
    //获取积分商品详情
    async getIntegralGoodsDetails() {
      await this.$API.user
        .IntegralCommodityDetails({
          object: {
            id: this.$route.query.integralGoodsId,
          },
        })
        .then((res) => {
          this.goods = res.data.data;

          if (this.goods.storeCommodityAttributeEntities) {
            this.goods.storeCommodityAttributeEntities.forEach((item) => {
              this.$set(item, "index", -1);
              item.attributeValue = item.attributeValue.split(",");
              item.filePath = item.filePath.split(",");
              item.id = item.id.split(",");
            });
          }

          this.goods.commodityContent = this.goods.commodityContent.replace(
            /src/g,
            "data-src"
          );

          let img = [];
          this.goods.filePath.split(",").forEach((element) => {
            this.imgLength++;
            if (element.endsWith(".mp4")) {
              this.goodsVideo = element;
            } else {
              img.push(element);
            }
          });
          this.goods.retailPrice = Math.trunc(
            preferential(res.data.data, "retailPrice").price
          );
          this.goods.tradePrice = Math.trunc(
            preferential(res.data.data, "tradePrice").price
          );
          if (this.userType === "PURCHASE") {
            this.goods.integral = this.goods.tradePrice;
          } else {
            this.goods.integral = this.goods.retailPrice;
          }

          this.goods.filePath = img;
          this.goodsStatus = 3;
          this.loading = false;
          this.isLoading = false;
          let shareInfo = {
            title: this.goods.commodityName,
            desc: this.goods.characteristics,
            imgUrl: this.goods.filePath[0],
            link: window.location.hash,
          };
          WechatSharing(shareInfo);
        });
    },
    //商品价格
    async goodsPrice() {
      if (this.$route.query.commId) {
        var info = {
          commId: this.$route.query.commId,
        };
      } else {
        var info = {
          barcode: this.$route.query.barcode
            ? this.$route.query.barcode
            : this.barcode,
          enterpriseId: this.$route.query.enterpriseId
            ? this.$route.query.enterpriseId
            : this.enterpriseId,
        };
      }
      await this.$API.goods
        .getGoodsPrice({
          object: info,
        })
        .then((res) => {
          if (this.isLoading) {
            this.isLoading = false;
          }
          this.retailPrice = preferential(res.data.data, "retailPrice").price;
          this.tradePrice = preferential(res.data.data, "tradePrice").price;
          if (preferential(res.data.data) && !this.goodsStatus)
            this.goodsStatus = 4;
          this.priceDetails = res.data.data;
          this.getHotSelling();

          this.loading = false;
        })
        .catch((err) => {
          this.Toast(err);
          if (this.isLoading) {
            this.Toast("刷新失败");
            this.isLoading = false;
            this.loading = false;
          }
        });
    },
    //尝鲜价
    async getTastingPrice() {
      const data = await this.$API.goods.queryCommTastingPrice({
        object: {
          commId: this.goods.id,
          userId: this.userInfo.id,
        },
      });
      if (data.data.data && data.data.data.inventory) {
        this.goods.deduction = data.data.data.deduction;
        this.buyCount = 1;
        this.goodsStatus = 1;
      }
    },
    //秒杀价
    async getSeckillPrice() {
      const data = await this.$API.goods.querySeckillCommodityByCommId({
        object: {
          id: this.goods.id,
        },
      });
      if (!data.data.data) return;
      this.seckillPrice = data.data.data;
      if (
        this.seckillPrice.seckillEnableState === 2 ||
        !this.seckillPrice.restAccount
      )
        return;
      if (
        this.goodsStatus === 1 &&
        this.goods.entity.price <= this.seckillPrice.retailSeckillPrice
      ) {
        //如果有尝鲜价 比较价格选择展示尝鲜或秒杀
        return;
      }

      //当天距离秒杀开始时间戳
      let t = this.seckillPrice.seckillStartTime - new Date().getTime();
      //一天的时间戳
      let n = 24 * 60 * 60 * 1000;
      // let n =  60 * 60 * 1000;
      if (t < n) this.goodsStatus = 2;
      if (this.seckillPrice.seckillEnableState === 1) {
        this.buyCount = this.seckillPrice.buyCount;
        this.goods.deduction = this.seckillPrice.deduction;
      }
      // this.seckillPrice.seckillEnableState
      // console.log(this.seckillPrice.seckillEnableState);
    },
    //热销榜单
    async getHotSelling() {
      const data = await this.$API.hotList.HotSaleActivityList({
        object: {
          categoryId: this.goods.classifyRsps[0].mid,
        },
      });
      if (!data.data.data.length) return;
      this.rank = data.data.data.find((item) => {
        return item.commId === this.priceDetails.commId;
      });
    },
    //预估佣金
    async getCommodityCommission() {
      //0无特殊,1新人尝鲜,2限时秒杀,3积分商品,4打折
      let price;
      if (this.goodsStatus === 0 || this.goodsStatus === 4) {
        price = this.retailPrice;
      } else if (this.goodsStatus === 1) {
        price = this.goods.entity;
      } else if (
        this.goodsStatus === 2 &&
        this.seckillPrice.seckillEnableState === 1
      ) {
        price = this.seckillPrice.retailSeckillPrice;
      }
      await this.$API.goods
        .commodityCommission({
          object: {
            promoterUuid: this.promoter.userId,
            salePrice: Number(price),
            tradePrice: Number(this.tradePrice),
          },
        })
        .then((res) => {
          this.commission = res.data.data;
        });
    },
    //修改轮播图数量
    onChange(index) {
      let video = document.querySelectorAll("video")[0];
      if (video) video.pause();
      this.current = index;
    },
    //组件轮播图数组
    imgPreview(i) {
      // console.log(i);
      ImagePreview({
        images: this.goods.filePath,
        startPosition: i,
      });
    },
    //加购购买弹窗
    buyShowMethod() {
      if (
        this.goodsStatus === 3 &&
        this.goods.integral > this.maxIntegralBank.integral
      )
        return;
      if (this.isLogin) {
        this.buyShow = true;
      } else {
        this.show2 = true;
      }
    },
    //立即购买
    onBuyClicked() {
      if (this.userType === "PURCHASE") {
        this.Toast.fail("  店长请使用  PDA终端开展店长业务。");
      } else {
        if (this.tzInfo === null) {
          this.Toast.fail(
            "您当前使用的不是店长的二维码或链接，请联系店长获取二维码或链接"
          );
          return;
        }
        var loadingToast = this.Toast.loading({
          duration: 0,
          message: "加载中...",
          forbidClick: true,
        });

        // if (
        //   this.seckillPrice.seckillEnableState !== 1 &&
        //   this.goodsStatus === 2
        // ) {
        //   this.Toast.fail("活动未开始");
        //   return;
        // }

        //商品规格选择
        let attributeId = [];
        let attributeName = [];
        if (this.goods.storeCommodityAttributeEntities) {
          try {
            this.goods.storeCommodityAttributeEntities.forEach((item) => {
              if (item.index === -1) {
                throw Error(`请选择商品${item.attributeName}`);
              }
              attributeName.push(item.attributeValue[item.index]);
              attributeId.push(item.id[item.index]);
            });
          } catch (e) {
            this.Toast.fail(e.message);
            return;
          }
        }
        let type;
        let price;
        let typeId;
        let freeShipping;
        let storeTastingActivityInfoEntity;
        if (this.goodsStatus === 1) {
          //新人
          storeTastingActivityInfoEntity = this.goods.entity;
          typeId = this.goods.entity.id;
          price = this.goods.entity.price;
          freeShipping = this.goods.freeShipping;
          type = 1;
        } else if (
          this.seckillPrice.seckillEnableState === 1 &&
          this.goodsStatus === 2
        ) {
          //秒杀
          // if (this.seckillPrice.restAccount === 0) {
          //   this.Toast.fail({
          //     message: "活动库存已售罄",
          //   });
          //   price = this.retailPrice;
          //   typeId = "";
          //   freeShipping = this.goods.freeShipping;
          //   type = 0;
          // } else { }
          if (this.quantity2 > this.seckillPrice.restAccount) {
            this.Toast.fail("活动库存不足");
            return;
          }
          price = this.seckillPrice.retailSeckillPrice;
          typeId = this.seckillPrice.seckillInfoCommodityId;
          freeShipping = this.seckillPrice.freeShipping;
          type = 2;
        } else {
          //正常
          price = this.retailPrice;
          typeId = "";
          freeShipping = this.goods.freeShipping;
          type = 0;
        }
        let order = [
          {
            storeId: this.goods.storeId,
            storeName: this.goods.storeName,
            amount: Math.round(price * this.quantity2 * 100) / 100,
            integral:
              this.goodsStatus === 3
                ? this.goods.integral * this.quantity2
                : undefined,
            freeShipping: freeShipping,
            message: "",
            orderCardListItems: [
              {
                ...this.goods,
                storeTastingActivityInfoEntity: storeTastingActivityInfoEntity,
                attributeId: attributeId.join(","),
                attributeName: attributeName.join("/"),
                quantity: this.quantity2,
                retailPrice:
                  this.goodsStatus === 3
                    ? this.goods.retailPrice
                    : Number(price),
                tradePrice:
                  this.goodsStatus === 3
                    ? this.goods.tradePrice
                    : this.tradePrice,
                commId: Number(this.goods.id),
                filePath: this.goods.filePath[0],
                type: type,
                typeId: typeId,
                deduction: this.goodsStatus === 3 ? true : this.goods.deduction,
              },
            ],
            // this.goodsStatus === 3 ?
            // this.quantity2
          },
        ];

        setTimeout(() => {
          loadingToast.clear();
          sessionStorage.setItem(window.btoa("order"), btoa(order, 1));
          this.$router.push("/confirmAnOrder");
        }, 500);
      }
    },
    //加入购物车
    async onAddCartClicked() {
      let attributeId = [];
      if (this.goods.storeCommodityAttributeEntities) {
        try {
          this.goods.storeCommodityAttributeEntities.forEach((item) => {
            if (item.index === -1) {
              throw Error(`请选择商品${item.attributeName}`);
            }
            attributeId.push(item.id[item.index]);
          });
        } catch (e) {
          this.Toast.fail(e.message);
          return;
        }
      }
      //如果是普通商品有尝鲜价的值 传尝鲜价type
      if (!this.goodsStatus && this.goods.entity) {
        var state = 1;
      }
      if (
        this.goodsStatus === 2 &&
        this.seckillPrice.seckillEnableState === 0
      ) {
        var state = 0;
      }

      await this.$API.order
        .InsertCusOrderCart({
          object: {
            attributeId: attributeId,
            barcode: this.goods.barcode,
            commId: this.goods.id,
            type: state !== undefined ? state : this.goodsStatus,
            typeId: this.goods.entity ? this.goods.entity.id : "",
            quantity: this.quantity2,
            storeId: this.goods.storeId,
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            this.$store.dispatch("user/getQuantity");
            this.buyShow = false;
            this.quantity2 = 1;
            this.Toast.success("添加购物车成功");
          } else {
            this.tastingTipsShow = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //选择商品sku
    selectAttribute(item, i) {
      if (item.index === -1 || item.index !== i) {
        item.index = i;
        if (JSON.parse(item.defaultFile.split(",")[0]))
          this.seleteImg = item.filePath[i];
      } else {
        item.index = -1;
        if (JSON.parse(item.defaultFile.split(",")[0])) this.seleteImg = "";
      }
    },
    //商品规格弹窗关闭事件
    closeShow() {
      if (this.goods.storeCommodityAttributeEntities) {
        this.goods.storeCommodityAttributeEntities.forEach((goods) => {
          goods.index = -1;
        });
        this.seleteImg = "";
      }
    },
    //倒计时结束
    finish() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getDetails();
      }, 1500);
    },
    //回到顶部
    // backToTop() {
    //   let top = document.getElementById("goods").scrollTop;
    //   // 实现滚动效果

    //   const timeTop = setInterval(() => {
    //     document.getElementById("goods").scrollTop = top -= 60;
    //     if (top <= 0) {
    //       clearInterval(timeTop); //清除定时器
    //     }
    //   }, 10);
    // },
    saveThePoster() {
      sharePopup({
        ...this.goods,
        ...this.priceDetails,
        retailPrice: this.retailPrice,
        seckillPrice: this.seckillPrice,
        goodsStatus: this.goodsStatus,
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollx, true);
    sharePopup(null, true);
  },
};
</script>

<style lang="scss" scoped>
#goods {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 50px;
  overflow-y: scroll;
  background-color: #f5f5f5;
  .nav_title {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-width: 500px;
    margin: 0 auto;
    height: 44px;
    z-index: 9;
    font-size: 16px;
    color: #333;
    text-align: center;
    padding: 11px 0;
    font-weight: bold;
    transition: 0.3s all;
    span {
      position: absolute;
      top: 6px;
      left: 15px;
      width: 32px;
      height: 32px;
      background: rgba($color: #fff, $alpha: 0.75);
      border-radius: 50%;
      .van-icon {
        font-size: 18px;
        display: flex;
        height: 32px;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .goods_details {
    max-width: 500px;
    margin: 0 auto;
    ::v-deep .van-swipe {
      background: #fff;
      text-align: center;
      .van-swipe__track {
        align-items: center;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
      .custom_indicator {
        position: absolute;
        right: 15px;
        bottom: 30px;
        font-size: 12px;
        color: #fff;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        line-height: 20px;
        width: 40px;
        height: 20px;
        padding-left: 18px;
        span {
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.4);
          width: 20px;
          height: 20px;
          border-radius: 10px 0 10px 10px;
        }
      }
    }
    .taste {
      position: relative;
      top: -20px;
      background: #fef6e9;
      border-radius: 20px;
      padding: 16px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .taste_price {
        .price {
          display: flex;
          align-items: center;
          h3 {
            font-size: 24px;
            color: #f91c12;
            margin-right: 8px;
            span {
              font-size: 12px;
            }
          }
          p {
            padding: 2px 8px;
            background: #fb4e10;
            border-radius: 10px;
            font-size: 12px;
            color: #fff;
          }
        }
        .old_price {
          font-size: 16px;
          color: #999999;
          margin-top: 2px;
          text-decoration: line-through;
        }
      }
      .right {
        color: #fb4e10;
        display: flex;
        align-items: center;
        span {
          width: 16px;
          height: 16px;
          background: #fb4e10;
          border-radius: 50%;
          margin-left: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          .svg-icon {
            color: #fff;
            font-size: 10px;
          }
        }
      }
    }
    .seckill {
      position: relative;
      top: -20px;
      background: linear-gradient(180deg, #fb4e10 0%, #f91c12 100%);
      border-radius: 20px;
      padding: 16px 15px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      .seckill_price {
        .price {
          display: flex;
          align-items: center;
          h3 {
            margin-right: 8px;
            font-size: 24px;
            span {
              font-size: 12px;
            }
          }
          .progress_bar {
            width: 64px;
            height: 16px;
            background: #ff9570;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            span {
              font-size: 12px;
              // position: relative;
              z-index: 1;
            }
          }
          .progress_bar::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: var(--barWidth);
            background: linear-gradient(180deg, #f99571 0%, #fb4e10 100%);
            border-radius: 8px;
          }
        }
        p {
          margin-top: 4px;
          font-size: 16px;
          text-decoration: line-through;
        }
      }
      .countdown {
        h3 {
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          span {
            width: 16px;
            height: 16px;
            background: #ffffff;
            border-radius: 50%;
            margin-left: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            .svg-icon {
              color: #fb4e10;
              font-size: 10px;
            }
          }
        }
        p {
          margin-top: 8px;
          font-size: 12px;
          ::v-deep .van-count-down {
            display: inline-block;
            .block {
              display: inline-block;
              border-radius: 2px;
              width: 20px;
              height: 20px;
              color: #f91c12;
              background: #fff;
              margin: 0 5px;
              text-align: center;
            }
            .colon {
              color: #fff;
              font-weight: bold;
            }
          }
        }
      }
    }
    .details {
      padding: 0 15px;
      position: relative;
      top: -20px;
      background: #f5f6fa;
      border-radius: 20px 20px 0px 0px;
      padding-top: 20px;
      .details_box {
        padding: 16px 12px;
        margin-bottom: 12px;
        border-radius: 10px;
        background: #fff;

        .price {
          color: #999999;
          margin-bottom: 12px;
          span {
            font-size: 12px;
            color: #f91c12;
            b {
              font-size: 24px;
            }
          }
          .old_price {
            padding-left: 6px;
            color: #999;
            font-size: 14px;
            text-decoration: line-through;
          }
        }
        .integral {
          font-size: 24px;
          color: #fb4e10;
          display: inline-block;
          margin-bottom: 12px;
        }
        .goods_name {
          display: flex;
          margin-bottom: 12px;
          .new_icon {
            width: 36px;
            height: 22px;
            line-height: 22px;
            background: linear-gradient(180deg, #2d87ff 0%, #0f63d5 100%);
            border-radius: 4px;
            color: #fff;
            flex-shrink: 0;
            text-align: center;
            margin-right: 12px;
          }
          .name {
            h3 {
              font-size: 16px;
              color: #333333;
            }
            p {
              margin-top: 6px;
              font-size: 12px;
              color: #999999;
            }
          }
        }
        .hot_selling {
          background: #fcf1f5;
          border-radius: 4px;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          .left {
            .icon {
              background: #f9345d;
              border-radius: 4px;
              color: #fff;
              padding: 1px 6px;
            }
            b {
              color: #f9345d;
              margin-left: 16px;
            }
          }
          .right {
            width: 16px;
            height: 16px;
            background: #f9345d;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 50%;
            .svg-icon {
              width: 100%;
              color: #fff;
              font-size: 10px;
            }
          }
        }
        .bar_code {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          p {
            color: #999;
            span {
              color: #333;
            }
          }
          a {
            border-radius: 24px;
            padding: 4px 12px;
            border: 1px solid #c9c9c9;
            color: #0f69e2;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-shrink: 0;
            .svg-icon {
              font-size: 16px;
              margin-right: 4px;
            }
          }
        }
        .van-cell-group {
          .van-cell {
            background: #f5f6fa;
            padding: 15px 12px;
            border-radius: 10px;
            .van-cell__title {
              color: #333;
              span {
                font-weight: bold;
                font-size: 14px;
              }
            }
          }
        }
      }
      .limit {
        margin-bottom: 12px;
        border-radius: 10px;
        .van-cell {
          padding: 13px 12px;
          border-radius: 10px;
          .van-cell__title {
            display: flex;
            line-height: 20px;
            // height: 20px;
            align-items: center;
            .title {
              color: #999999;
              margin-right: 20px;
              flex-shrink: 0;
            }
            .limit_num {
              margin-left: 8px;
              border-radius: 4px;
              border: 1px solid #ff7577;
              color: #fc393c;
              padding: 0 4px;

              font-size: 12px;
            }
          }
        }
      }
      .dispatching {
        background: #fff;
        padding: 16px 12px;
        border-radius: 10px;
        li {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          span:nth-child(1) {
            width: 28px;
            color: #999999;
            margin-right: 20px;
          }
        }
        li:last-child {
          margin: 0;
        }
      }
    }
    .graphic_details {
      padding: 0 15px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: -8px;
      padding-bottom: 10px;
      ::v-deep img {
        display: block;
      }
      ::v-deep img:nth-child(1) {
        border-radius: 10px 10px 0 0;
      }
      ::v-deep img:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }
  ::v-deep .tasing_tips {
    .van-dialog__header {
      text-align: right;
      padding-top: 20px;
      padding-right: 20px;
      .svg-icon {
        font-size: 14px;
      }
    }
    .van-dialog__message {
      padding: 0 24px;
      font-size: 15px;
      color: #333;
    }
  }
  ::v-deep .characteristic_show {
    padding-bottom: 30px;
    .van-cell {
      .van-cell__title {
        width: auto;
        flex: none;
        color: #666666 !important;
      }
      .van-cell__value {
        text-align: start;
        padding-left: 36px;
        color: #333333 !important;
      }
    }
  }
  .buy_show {
    overflow: unset;
    // height: 60%;
    .van-action-sheet__content {
      overflow: unset;
    }
    .content {
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      .top {
        display: flex;
        position: relative;
        top: -16px;
        z-index: 9999;
        padding: 0 15px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 5px;
          margin-right: 12px;
        }
        .price {
          display: flex;
          flex-direction: column;
          justify-content: center;
          b {
            span {
              font-size: 12px;
              font-style: normal;
              display: inline-block;
              transform: scale(0.8);
              -webkit-transform: scale(0.8);
              -moz-transform: scaleY(0.8);
              position: relative;
              left: 2px;
              top: 1px;
              z-index: 0;
            }
            margin-left: -4px;
            font-size: 16px;
            line-height: 24px;
            color: #fb4e10;
          }
          p {
            line-height: 20px;
            color: #333333;
          }
        }
        .svg-icon {
          position: absolute;
          top: 39px;
          right: 15px;
          color: #868686;
        }
      }
      .Sku {
        height: 30vh;
        overflow-y: scroll;
        padding: 0 15px;
        margin-bottom: 15px;
        .specification {
          > div {
            p {
              margin-bottom: 6px;
            }
            ul {
              display: flex;
              flex-wrap: wrap;
              li {
                margin-bottom: 12px;
                transition: 0.2s all;
                line-height: 26px;
                background: #f5f5f5;
                border-radius: 13px;
                padding: 0 10px;
                color: #666;
                margin-right: 10px;
              }
            }
          }
        }
        .quantity {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
    .van-goods-action {
      padding: 0 15px;
      position: unset;
      height: 34px;
      flex: 1;
      .van-button {
        margin: 0;
        height: 34px;
        font-size: 16px;
      }
    }
  }
  .goods_box > .bottom_nav {
    background: #ffffff;
    padding: 0 15px;
    z-index: 1000;
    height: 50px;
    max-width: 500px;
    margin: 0 auto;
    .promoter_tips {
      position: fixed;
      max-width: 500px;
      margin: 0 auto;
      left: 0;
      right: 0;
      padding: 0 15px;
      bottom: 50px;
      z-index: 10;
      .notCertifiedYet {
        width: 100%;
        margin-bottom: 8px;
        background: rgba($color: #333, $alpha: 0.92);
        padding: 6px 15px;
        border-radius: 99px;
        display: flex;
        align-items: center;
        p {
          color: #ffffff;
          flex: 1;
          line-height: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        button {
          height: 32px;
          line-height: 14px;
          color: #333333;
          border-radius: 16px;
          padding: 0 8px;
          border: none;
          background: #fff;
          margin: 0 10px 0 2px;
        }
        .svg-icon {
          font-size: 12px;
          color: #ffffff;
        }
      }
    }
    .commission {
      position: fixed;
      max-width: 500px;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: 50px;
      z-index: 10;
      background: #feedd8;
      display: flex;
      align-items: center;
      padding: 9px 15px;
      p {
        flex: 1;
        color: #f98f10;
        span {
          font-size: 16px;
          font-weight: bold;
          color: #f91c12;
          margin-left: 4px;
        }
        .svg-icon {
          margin-left: 6px;
        }
      }
      button {
        background: #fff;
        border: none;
        padding: 6px 13px;
        border-radius: 99px;
      }
    }
    .integral_tips {
      position: fixed;
      bottom: 50px;
      // height: 50px;
      background: #feedd8;
      display: flex;
      align-items: center;
      justify-content: space-between;
      left: 0;
      right: 0;
      max-width: 500px;
      margin: 0 auto;
      padding: 8px 15px;
      .left {
        p {
          color: #f98f10;
          line-height: 20px;
        }
        span {
          display: block;
          color: #999999;
          font-size: 12px;
          transform: scale(0.9);
          margin-left: -10px;
          .van-icon {
            margin-right: 2px;
          }
        }
      }
      .right a {
        color: #108bff;
      }
    }
    .van-button {
      margin: 0;
      font-size: 16px;
      flex: auto;
    }
    ::v-deep .van-goods-action-icon {
      .van-info {
        top: 7px;
        background: #fb4e10;
      }
    }
    .van-button {
      margin-left: 12px;
      width: 102px;
      padding: 0;
      height: 34px;
    }
    .van-button:nth-child(3) {
      margin-left: 32px;
    }
  }
  ::v-deep .van-dialog {
    .van-dialog__content {
      text-align: center;
      padding: 20px 0;
      .svg-icon {
        position: absolute;
        right: 15px;
        top: 15px;
        color: #333;
        cursor: pointer;
      }
      .title {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 600;
      }
      .content {
        p {
          margin-bottom: 6px;
        }
        p:nth-child(1) {
          font-size: 18px;
          // font-weight: 600;
        }
        p:nth-child(2) {
          font-size: 14px;
          // font-weight: 600;
          color: #fb5d20;
        }
        > div {
          margin-top: 24px;
          display: flex;
          justify-content: space-evenly;
        }
      }
    }
  }
  ::v-deep .fyTips {
    .van-dialog__content {
      .svg-icon {
        color: #fff;
        right: 23px;
      }
      padding: 0;
      .header {
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 10px;
      }
      .content {
        line-height: 30px;
        padding-bottom: 30px;
      }
      .footer {
        button {
          border: 1px solid #ffc426;
          padding: 10px 22px;
          font-size: 16px;
          border-radius: 99px;
        }
        .more {
          background: #fff;
          color: #ffc426;
          margin-right: 8px;
        }
        .authentication {
          background: #ffc426;
          color: #fff;
          margin-left: 8px;
        }
      }
    }
  }
}
.van-empty {
  background-color: #f5f5f5;
  position: absolute;
  top: 46px;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>

<style lang="scss">
.graphic_details_content {
  img {
    display: block;
  }
}
</style>
